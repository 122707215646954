import React from 'react'
import * as styles from './product.module.scss'

const Product = ({ className, name, description, image, partNo, quantity, size, pdf, sds, sds2 }) => {
  return <div className={`${className} ${styles.product}`}>
    <div class="row">
      <div className="col-3">
        <div className="h-100 w-100" style={{backgroundImage: `url(${image})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}></div>
      </div>

      <div className="col-9">
        <h2 className="text-blue">{name}</h2>
        <p>{description}</p>

        <div className={`row ${styles.details}`}>
          <div className="col-auto">
            <h5 style={{fontWeight: 'bold'}}>PART #</h5>
            <span className="text-muted">{partNo}</span>
          </div>
          <div className="col-auto border-primary" style={{borderLeft: '1px solid black'}}>
            <h5 style={{fontWeight: 'bold'}}>QUANTITY</h5>
            <span className="text-muted">{quantity}</span>
          </div>
          {size && <div className="col-auto border-primary" style={{borderLeft: '1px solid black'}}>
            <h5 style={{fontWeight: 'bold'}}>SIZE</h5>
            <span className="text-muted">{size}</span>
          </div>}
        </div>

        <div className="row">
            <div className="col mt-3">
                <a className="btn btn-blue" href={pdf} target="_blank" rel="noreferrer"><span className={`${sds2 ? 'd-none d-lg-inline' : ''}`}>PDF</span> Info <span className={`${sds2 ? 'd-none d-lg-inline' : ''}`}>Sheet</span></a>
                <a className="btn btn-secondary ml-2" href={sds} target="_blank" rel="noreferrer">SDS</a>
                {sds2 && <a className="btn btn-secondary ml-2" href={sds2} target="_blank" rel="noreferrer">SDS</a>}
            </div>
        </div>
      </div>
    </div>
  </div>
}

export default Product;
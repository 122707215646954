import React from 'react'
import Layout from '../components/layout/layout'
import PageHeader from '../components/pageheader/pageheader'
import Section from '../components/section/section'
import Product from '../components/product/product'

import AtfImage from '../images/products/atf-trans4mer.png'
import LiquifogImage from '../images/products/liquifog.png'
import LiquifogOilImage from '../images/products/liquifog-oil.png'
import DoubleShotImage from '../images/products/double-shot.png'
import FuelNhancerImage from '../images/products/fuel-nhancer.png'
import NgenPrepImage from '../images/products/ngen-prep.png'
import NgenSealerImage from '../images/products/ngen-sealer.png'
import BlueShieldImage from '../images/products/blue-shield.png'
import OilCleanerImage from '../images/products/oil-cleaner.png'
import PremiumAttImage from '../images/products/premium-att.png'
import AutoSealerImage from '../images/products/auto-sealer.png'
import HimiOilImage from '../images/products/himi-oil.png'

import ProductListPdf from '../assets/product-list.pdf'
import AtfPdf from '../assets/atf-trans4mer.pdf'
import AtfSds from '../assets/atf-trans4mer-sds.pdf'
import LiquifogPdf from '../assets/liquifog.pdf'
import LiquifogSds from '../assets/liquifog-sds.pdf'
import LiquifogOilPdf from '../assets/liquifog-oil.pdf'
import LiquifogOilSds from '../assets/liquifog-oil-sds.pdf'
import DoubleShotPdf from '../assets/double-shot.pdf'
import DoubleShotCleanerSds from '../assets/double-shot-cleaner-sds.pdf'
import FuelNhancerPdf from '../assets/fuel-nhancer.pdf'
import FuelNhancerSds from '../assets/fuel-nhancer-sds.pdf'
import NgenPrepPdf from '../assets/ngen-prep.pdf'
import NgenPrepSds from '../assets/ngen-prep-sds.pdf'
import NgenSealerPdf from '../assets/ngen-sealer.pdf'
import NgenSealerSds from '../assets/ngen-sealer-sds.pdf'
import BlueShieldPdf from '../assets/blue-shield.pdf'
import BlueShieldSds from '../assets/blue-shield-sds.pdf'
import OilCleanerPdf from '../assets/oil-cleaner.pdf'
import OilCleanerSds from '../assets/oil-cleaner-sds.pdf'
import PremiumAttPdf from '../assets/premium-att.pdf'
import PremiumAttSds from '../assets/premium-att-sds.pdf'
import AutoSealerPdf from '../assets/auto-sealer.pdf'
import AutoSealerSds from '../assets/auto-sealer-sds.pdf'
import HimiOilPdf from '../assets/himi-oil.pdf'
import HimiOilSds from '../assets/himi-oil-sds.pdf'

const ProductPage = () => {
  return (
    <Layout>

      <PageHeader title="Our Products" />

      <Section color="white">
        <div className="container">
          <div className="text-center">
            <h3 className="section-subheading text-muted mb-5">NGEN features revolutionary and time tested formulas that are both safe and effective. Call us today to see how NGEN's comprehensive product line can help you and your customers.</h3>
            <a href={ProductListPdf} target="_blank" className="btn btn-blue mb-5" rel="noreferrer">Complete Product Line PDF</a>
          </div>
          <div className="row align-items-center">
            <Product className="col-lg-6 mt-5" name="ATF Trans4mer&trade;" image={AtfImage} description="Transforms classic ATF into a comprehensive replacement for specialty ATF" partNo="71083" size="10 OZ." quantity="12/CASE" pdf={AtfPdf} sds={AtfSds} />
            <Product className="col-lg-6 mt-5" name="LiquiFog&trade; GDI Cleaner" image={LiquifogImage} description="Carbon Crushing formula for all gasoline engines" partNo="71434" size="16 OZ." quantity="6/CASE" pdf={LiquifogPdf} sds={LiquifogSds} />
            <Product className="col-lg-6 mt-5" name="LiquiFog&trade; For Oil" image={LiquifogOilImage} description="Add to Fresh Oil to reduce oil burn off and increase performance" partNo="71436" size="10 OZ." quantity="12/CASE" pdf={LiquifogOilPdf} sds={LiquifogOilSds} />
            <Product className="col-lg-6 mt-5" name="Double Shot&trade; ​Fuel Nhancer&trade;" image={DoubleShotImage} description="2 step deposit-removing service,​ 1 for gas tank. 1 for induction system." partNo="71003" quantity="12/KITS" pdf={DoubleShotPdf} sds={FuelNhancerSds} sds2={DoubleShotCleanerSds} />
            <Product className="col-lg-6 mt-5" name="Solo Shot&trade; ​Fuel Nhancer&trade;" image={FuelNhancerImage} description="Concentrated in-tank formula removes mileage &amp; performance ​robbing deposits" partNo="71012" size="6 OZ." quantity="12/CASE" pdf={FuelNhancerPdf} sds={FuelNhancerSds} />
            <Product className="col-lg-6 mt-5" name="NGEN Prep&trade;" image={NgenPrepImage} description="Concentrated formula helps ​prepare engine for new oil" partNo="71008" size="8 OZ." quantity="12/CASE" pdf={NgenPrepPdf} sds={NgenPrepSds} />
            <Product className="col-lg-6 mt-5" name="NGEN Sealer" image={NgenSealerImage} description="Stops most leaks, weeps &amp; seeps" partNo="71015" size="12 OZ." quantity="12/CASE" pdf={NgenSealerPdf} sds={NgenSealerSds} />
            <Product className="col-lg-6 mt-5" name="Blue Shield&trade; NGEN Treatment ​with PTFE" image={BlueShieldImage} description="A complex PTFE formula that reduces friction and can increase ​mileage" partNo="71032" size="16 OZ." quantity="12/CASE" pdf={BlueShieldPdf} sds={BlueShieldSds} />
            <Product className="col-lg-6 mt-5" name="Oil System Cleaner" image={OilCleanerImage} description="Classic Oil System Cleaner that safely and quickly cleans engine deposits" partNo="71016" size="16 OZ." quantity="24/CASE" pdf={OilCleanerPdf} sds={OilCleanerSds} />
            <Product className="col-lg-6 mt-5" name="Premium Automatic Transmission ​Treatment" image={PremiumAttImage} description="Refresh! Renew! Revive!&trade;. Superior additive package treats leftover fluid in torque converter​. ​Reduces friction &amp; wear." partNo="71085" size="12 OZ." quantity="12/CASE" pdf={PremiumAttPdf} sds={PremiumAttSds} />
            <Product className="col-lg-6 mt-5" name="Automatic Transmission Sealer &amp; Conditioner" image={AutoSealerImage} description="Stops most leaks and lightly conditions ATF" partNo="71088" size="12 OZ." quantity="12/CASE" pdf={AutoSealerPdf} sds={AutoSealerSds} />
            <Product className="col-lg-6 mt-5" name="HiMi Oil Trans4mer&trade;" image={HimiOilImage} description="Transforms your oil choice into a high mileage oil change" partNo="71061" size="12 OZ." quantity="12/CASE" pdf={HimiOilPdf} sds={HimiOilSds} />
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default ProductPage
